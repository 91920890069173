import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppNutzeDeinWahlrecht = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Gehe zur Wahl - für unsere Zukunft in der EU! So geht's:"
      description="Folge dem Link und schicke diese Nachricht an möglichst viele Freunde und Bekannte auf Whatsapp.➡️"
      shareImage="deutsch"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hast du deutsche Staatsbürgerschaft und lebst in GB? Bei der Wahl zum Europäischen Parliament hast du Wahlrecht - aber nur wenn du wählen gehst, zählt auch deine Stimme!

WICHTIG!
💥Sende diese Nachricht an deine deutschen Freunde in GB.💥
Hier klicken: https://saveourfuture.uk/NutzeDeinWahlrecht

*Anmeldeschluss ist der 7. Mai (also am besten jetzt gleich registrieren!):*
Den Schritten hier folgen (3 Minuten):
https://saveourfuture.uk/simple
(Melde dich am besten zur Briefwahl an, dann kannst vor der Wahl du deine Stimme bequem abgeben wann du willst!)

💥Bitte leite diese Nachricht an deine deutschen Freunden in GB weiter!💥
Hier klicken: https://saveourfuture.uk/NutzeDeinWahlrecht

🗣 * DEINE STIMME ZÄHLT!!! * 🗣`}
    />
    <Interstitial>
      <p>Wählen Sie fünf beste Freunde oder Gruppen aus, um Ihre Whatsapp-Nachricht zu senden</p>
      <p>Die Nachricht wird jetzt generiert</p>
      <p>Sie können die Nachricht im nächsten Schritt personalisieren…</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppNutzeDeinWahlrecht.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppNutzeDeinWahlrecht;

export const query = graphql`
  query NutzeDeinWahlrechtQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
